import classNames from 'classnames';
import React from 'react';
import { Header } from '../components/Header';
// import { Footer } from '../components/Footer';
import './Layout.css';

export const Layout = ({ children, className = '', style = undefined }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <div className={classNames('w-full h-full', className)}>
      <Header />
      <div
        className={className}
        style={style}
        // style={{
        //   margin: `0 auto`,
        // padding: `0 1.0875rem 1.45rem`,
        // maxWidth: 960,
        // }}
      >
        <main>{children}</main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
