import React from 'react';
import LogoTypoSVG from '../resources/logo_typo.svg';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';

const PingIndicator = () => (
  <span className="flex absolute -mt-5 ml-4">
    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
    <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
  </span>
);

const NavItems = ({ className = 'hidden md:flex px-4 ml-auto font-heading space-x-8' }) => {
  return (
    <ul className={className}>
      <li>
        <Link to="/about" className="opacity-50 hover:opacity-90" activeStyle={{ opacity: 1 }}>
          ABOUT US
        </Link>
      </li>
      <li>
        <Link to="/archive" className="opacity-50 hover:opacity-90" activeStyle={{ opacity: 1 }}>
          ARCHIVE
        </Link>
      </li>
      <li>
        <Link to="/shop" className="opacity-50 hover:opacity-100" activeStyle={{ opacity: 1 }}>
          SHOP
        </Link>
      </li>
      <li>
        <Link to="/community" className="opacity-50 hover:opacity-90" activeStyle={{ opacity: 1 }}>
          COMMUNITY
        </Link>
      </li>
      <li>
        <Link to="/contact" className="opacity-50 hover:opacity-90" activeStyle={{ opacity: 1 }}>
          CONTACT
        </Link>
      </li>
    </ul>
  );
};

export const Header = ({ id = 'header', background = undefined }) => {
  return (
    <nav className="flex justify-between text-white w-screen">
      <div className="px-5 xl:px-12 py-6 flex w-full items-center">
        <Link to="/" className="text-3xl font-bold">
          <LogoTypoSVG className="h-9 w-auto text-white" />
          {/* <img className="h-9" src="logo.png" alt="logo">  */}
          <span className="sr-only">WLPO</span>
        </Link>
        {/* <!-- Nav Links --> */}
        <NavItems className="hidden md:flex px-4 ml-auto font-heading space-x-8" />
      </div>
      {/* <!-- Responsive navbar --> */}
      <Menu
        right
        pageWrapId="___gatsby"
        styles={{
          bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            right: '24px',
            top: '24px',
          },
          bmBurgerBars: {
            background: '#373a47',
          },
          bmBurgerBarsHover: {
            background: '#a90000',
          },
          bmCrossButton: {
            width: '48px',
            height: '48px',
            right: '28px',
            top: '24px',
          },
          bmCross: {
            background: '#bdc3c7',
          },
          bmMenuWrap: {
            position: 'fixed',
            height: '100%',
          },
          bmMenu: {
            background: 'rgba(32, 32, 34, 0.9)',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
          },
          bmMorphShape: {
            fill: '#373a47',
          },
          bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em',
          },
          bmItem: {
            display: 'inline-block',
          },
          bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)',
          },
        }}
        customBurgerIcon={
          <button className="navbar-burger self-center mr-12 md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 hover:text-gray-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        }
      >
        <div className="h-full">
          <NavItems className="flex flex-col gap-2 font-heading" />
        </div>
      </Menu>
      {/* <Modal
        page
        trigger={
          <a className="navbar-burger self-center mr-12 md:hidden" href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 hover:text-gray-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </a>
        } */}
      {/* > */}
    </nav>
  );
};
